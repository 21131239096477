<view-header label="Asukohad"></view-header>

<view-content>
  <div class="flex flex-col gap-5x">
    @for(location of locations; track location.id) {
    <div
      class="flex cursor-pointer items-center gap-5x border border-grey-20 pr-8x"
      matRipple
      [routerLink]="location.id"
    >
      <div class="flex items-center gap-6x">
        <!-- LOCATION IMAGE -->
        <div
          class="flex size-25x min-w-25x items-center justify-center bg-grey-10"
        >
          @if(location.thumbnail) {
          <img
            class="h-full w-full object-fill"
            [src]="'data:' + location.thumbnailContentType + ';base64,' + location.thumbnailSmall"
            alt="Location thumbnail"
          />
          } @else {
          <svg-icon name="monochromeAddress"></svg-icon>
          }
        </div>
        <!-- LOCATION INFO -->
        <div class="flex flex-col gap-1x">
          <div
            class="line-clamp-1 font-display text-sm font-medium text-grey-100"
          >
            {{ location.name }}
          </div>
          <div class="line-clamp-1 font-text text-sm text-grey-70">
            {{ location.address }}
          </div>
        </div>
      </div>
    </div>
    }
  </div>
</view-content>
